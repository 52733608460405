import React from 'react';
import Grid from '@material-ui/core/Grid';
import '../style/page.scss';

import Page from '../components/page';

const NotFoundPage: React.ReactNode = () => (
  <Page isFetching={false}>
    <Grid container spacing={2} justify="center" alignItems="center" className={'rootContainer'}>
      <Grid item xs={12}>
        <h1>Page not found</h1>
      </Grid>
    </Grid>
  </Page>
);

export default NotFoundPage;
